@use 'theme';
@use 'fonts';
@use 'baseline';

.wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  color: theme.get('textColor');
}

.heading {
  margin: theme.spacing(8, 0);
  font-size: fonts.size('huge');
  font-weight: fonts.weight('black');
}

.mainWrapper {
  width: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.message {
  font-size: fonts.size('great');
  text-align: center;
}

.description {
  margin-top: theme.spacing(0.75);
  font-size: fonts.size('xSmall');
  text-align: center;
  white-space: pre-wrap;
}

.icon {
  height: 85px;
  width: auto;
}

.footer {
  height: 64px;
  width: 90%;
  margin-bottom: theme.spacing(2.5);
  position: fixed;
  bottom: 0;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateX(-50%);
  background-color: theme.get('backgroundColor');
  font-size: fonts.size('xSmall');
  flex-wrap: wrap;
}

.phone {
  display: flex;
  align-items: center;
  margin-right: theme.spacing(0.5);
}

.link {
  @include baseline.reset-link;
  color: theme.get('darkActiveColor');

  &:hover {
    text-decoration: underline;
  }
}
@use 'theme';
@use 'borders';
@use 'transitions';

@keyframes fadeIn {
  from {
    transform: scale(0.75);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.wrapper {
  display: flex;
  align-items: flex-end;
  position: fixed;
  top: 16px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999999;
  max-height: calc(100vh - theme.spacing(6));
}

.innerWrapper {
  display: grid;
  gap: theme.spacing(1);
}

.message {
  background-color: theme.palette('white');
  border-radius: borders.radius('md');
  overflow: hidden;
  animation: fadeIn var(--exitAnimationTime) ease;
  transition: transitions.get(('transform', 'opacity'), var(--exitAnimationTime));
}

.isExiting {
  transform: scale(0.75);
  opacity: 0;
}

@use 'theme';
@use 'fonts';
@use 'shadows';
@use 'breakpoints';
@use 'transitions';

.container {
  & .compare {
    opacity: 0;
    visibility: hidden;
    transform: scale(0.5);
    transition: transitions.get(('opacity', 'transform', 'visibility'), 200ms);
  
    &.visible {
      opacity: 1;
      visibility: visible;
      transform: scale(1);
    }
  }
  
  & .fab {
    @extend .compare;
    position: fixed;
    bottom: calc(16px + var(--compareFabBottomOffset, 0));
    right: 16px;
    border-radius: 50%;
    padding: theme.spacing(1.5);
    box-shadow: shadows.get('menuImage');
    z-index: 12;
    
    @include breakpoints.up('small') {
      right: 32px;
      bottom: 16px;
    }
  }
  
  & .floatingPanel {
    @extend .compare;
  }
}

.iconWrapper {
  position: relative;
  display: flex;
  overflow: visible;

  & .icon {
    width: 32px;
    height: 32px;
    color: theme.palette('white');
    fill: none;
  }
}

.sticker {
  position: absolute;
  top: -2px;
  right: -2px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: theme.palette('white');
  color: theme.get('darkActiveColor');
  border-radius: 18px;
  min-width: 18px;
  height: 18px;
  padding: theme.spacing(0, 0.5);
  font-size: fonts.size('tiny');
  font-style: normal;
}

.loader {
  position: absolute;
  top: -2px;
  right: -2px;
}

@use 'theme';

@keyframes rotate {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loader {
  animation: rotate infinite 1s;
}

.dark {
  fill: theme.get('loaderPrimaryColor');
}
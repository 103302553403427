@layer defaults, utilities;

@layer defaults {
  body {
    margin: 0;
    padding: 0;
    font-family: "Lato", "Arial", sans-serif;
    overflow-x: hidden;
    color: var(--textColor);
  }

  input {
    font-family: "Lato", "Arial", sans-serif;
  }

  *, *::before, *::after {
    box-sizing: border-box;
  }

  @-moz-document url-prefix() {
    body *:not(#app) {
      scrollbar-width: thin;
      scrollbar-color: transparent transparent;
    }

    body *:not(#app):hover {
      scrollbar-color: var(--scrollbarBackground, rgba(25, 25, 25, 0.2)) transparent;
    }
  }

  body *:not(#app)::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  body *:not(#app)::-webkit-scrollbar-track {
    background-color: transparent;
  }

  body *:not(#app)::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 4px;
  }

  body *:not(#app):hover::-webkit-scrollbar-thumb,
  body *:not(#app)::-webkit-scrollbar-thumb:hover {
    background-color: var(--scrollbarBackground, rgba(25, 25, 25, 0.2)) !important;
  }
}
@use 'theme';
@use 'breakpoints';
@use 'transitions';
@use 'borders';

@mixin getLoadingAnimation ($name, $minWidth, $maxWidth) {
  @keyframes #{$name} {
    0% {
      left: -$minWidth;
      width: $minWidth;
      opacity: 0.4;
    };
    50% {
      left: calc(50% - $maxWidth / 2);
      width: $maxWidth;
      opacity: 0.6;
    };
    100% {
      left: calc(100% + $minWidth);
      width: $minWidth;
      opacity: 0.4;
    };
  }
};

@include getLoadingAnimation('loadIndeterminiate-mobile', 50px, 150px);
@include getLoadingAnimation('loadIndeterminiate-web', 75px, 300px);

.container {
  display: flex;
  width: 100%;
  height: 4px;
  opacity: 0;
  transition: transitions.get('opacity', 100ms);
}

.visible {
  opacity: 1;

  & .value {
    animation: loadIndeterminiate-mobile 2s ease infinite forwards;

    @include breakpoints.up('small') {
      animation: loadIndeterminiate-web 2s ease infinite forwards;
    }
  }
}

.absolute {
  position: absolute;
  max-width: 100%;
  overflow-x: hidden;
  top: 0;
  left: 0;
}

.fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: theme.palette('white');
}

.value {
  position: absolute;
  height: 100%;
  border-radius: borders.radius('xLg');
  animation: none;
  animation-delay: 200ms;
}

.primary {
  background-color: theme.get('loaderPrimaryColor');
}

.secondary {
  background-color: theme.get('shopColor');
}
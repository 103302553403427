@use 'theme';
@use 'fonts';
@use 'typography';

@layer utilities {
  @each $variant, $values in typography.$typography {
    .#{$variant} {
      @each $key, $value in $values {
        #{$key}: $value;
      }
    }
  }
  
  .wrapper {
    padding: theme.spacing(0);
    margin: theme.spacing(0);
  }
  
  .align {
    text-align: var(--align);
  }
  
  .display {
    display: var(--display);
  }
  
  .transform {
    transform: var(--transform);
  }
  
  .weight {
    font-weight: var(--weight);
  }
  
  .lineHeight {
    line-height: var(--lineHeight);
  }
  
  .ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: var(--ellipsisLines, 1);
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: var(--wordBreak);
    white-space: normal;
  }
}

@use 'theme';

.wrapper {
  display: flex;
  align-items: center;
  color: inherit;
  text-decoration: none;
  column-gap: theme.spacing(0.5);
  color: currentColor;

  &:hover {
    text-decoration: underline;
  }
}